@import url("https://fonts.googleapis.com/css?family=DM+Mono:300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600");

:root {
  --font-family-base: "Work Sans", "Roboto", sans-serif;
  --font-family-mono: "DM Mono", mono-space;
  --header-height: 87px;
  font-family: var(--font-family-base);
  font-size: 14px;
  @apply text-neutral-700;
}

.Beacon .BeaconFabButtonFrame {
  display: none;
}
