:root {
  --sidebar-open-width: 240px;
  --sidebar-close-width: 3.775em;
  --sidebar-width: var(--sidebar-close-width);

  --header-height: 42px;

  --last-child-min-height: 300px;
  --mobile-pb: 768px;
}

@media screen and (max-width: 768px) {
  :root {
    --sidebar-close-width: 0px;
  }
  body {
    .sidebar {
      height: 100%;
    }
    .sidebar-area,
    .sidebar-area--open {
      width: 0px !important;
    }
    .sidebar-area:not(.sidebar-area--open) .sidebar {
      width: 0px !important;
    }
    .sidebar-area--open .sidebar {
      width: var(--sidebar-open-width);
    }
    .body-height-after-header {
      @apply h-auto;
    }
    .body-height-after-header > *:last-child {
      @apply h-auto max-h-screen overflow-auto;
    }
  }
}

.sidebar-area--open + .body {
  --sidebar-width: var(--sidebar-open-width);
}

.main-grid {
  grid-template-columns: auto 1fr;
  grid-template-areas: "sidebar main";
  @apply transition grid relative h-screen overflow-hidden;
}

.sidebar-area {
  --hover-reveal-delay: 300ms;
  --label-reveal-duration: 200ms;
  transition: width 0.2s ease-in-out;
  width: var(--sidebar-close-width);
  grid-area: sidebar;
  position: relative;
  @apply h-screen overflow-x-visible;
}

.sidebar-area:not(.sidebar-area--open) .sidebar:hover {
  @apply shadow-2xl;
}

.logo__container {
  position: relative;
  @apply h-14 w-full mt-2 mb-6;
}
.logo {
  transition: opacity 0.2s linear;
  @apply absolute object-contain px-6 inset-0 h-full w-full object-left;
}

.sidebar-area--open .logo--full,
.sidebar:hover .logo--full,
.logo--icon {
  opacity: 1;
}
.logo--full,
.sidebar-area--open .logo--icon,
.sidebar:hover .logo--icon {
  opacity: 0;
}
.sidebar:hover {
  .logo--icon,
  .logo--full {
    transition-delay: var(--hover-reveal-delay);
  }
}

.nav__label {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--label-reveal-duration) linear,
    visibility 0s var(--label-reveal-duration) linear;
  @apply whitespace-nowrap ml-5 max-w-full overflow-ellipsis overflow-hidden;
}

.sidebar-area--open {
  width: var(--sidebar-open-width);
}
.sidebar-area--open .nav__label {
  opacity: 1;
  visibility: visible;
  transition:
    opacity var(--label-reveal-duration) linear,
    visibility 0s linear;
}
.sidebar:hover .nav__label {
  opacity: 1;
  visibility: visible;
  transition:
    opacity var(--label-reveal-duration) var(--hover-reveal-delay) linear,
    visibility 0s var(--hover-reveal-delay) linear;
}

.sidebar__user-section {
  @apply transition-all rounded-lg overflow-hidden mt-auto mb-3 mx-px py-2;
}

.sidebar-area--open .sidebar__user-section,
.sidebar:hover .sidebar__user-section {
  @apply px-2 mx-3;
}

.sidebar:hover .sidebar__user-section {
  transition-delay: var(--hover-reveal-delay);
}

.sidebar:hover {
  transition-delay: var(--hover-reveal-delay);
  width: var(--sidebar-open-width);
}

.sidebar {
  width: var(--sidebar-close-width);
  transition: all 0.2s ease-in-out;
  will-change: width;
  z-index: 2999;
  position: relative;
  @apply h-full w-full;
}

.sidebar {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-neutral-200;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-neutral-400 rounded;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-neutral-500;
  }
}

.body {
  grid-area: main;
  overflow-y: auto;
  position: relative;
}

.body-height-after-header > * {
  @apply flex-none;
}
.body-height-after-header > *:last-child {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  /* min-height: var(--last-child-min-height); */
}
.body-height-after-header {
  height: calc(100vh - var(--header-height) - var(--space-after-header, 0px));
  @apply flex flex-col;
}
